<template>
  <h1 class="title is-3">Tipps zur Bedienung</h1>
  <p>
  </p>

  <h2 class="subtitle is-5">Videoanleitungen</h2>
  <div class="content">
    <p>
      Hinweise zur Bedienung können sich hier angeschaut werden:
      <a href="https://youtube.com/playlist?list=PL5ZUTHo0MPd6GvUm2oiS8pBzhYZFloZhn" target="blank_">Youtube Playliste</a>
    </p>

    <iframe width="100%" height="400px" src="https://www.youtube.com/embed/videoseries?list=PL5ZUTHo0MPd6GvUm2oiS8pBzhYZFloZhn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>

</template>


<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TipsDE",
  components: {},
  setup(){}
});
</script>